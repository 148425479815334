<template>
  <table class="tabla-nutricional table table-sm table-striped table-hover table-responsive mt-5">
    <caption>
      {{
        $t("tablasNutricionales.caption")
      }}
    </caption>
    <thead class="text-uppercase text-center">
      <tr>
        <th scope="col">{{ $t('tablasNutricionales.energia_y_nutrientes') }}</th>
        <th scope="col">{{ $t('tablasNutricionales.unidades') }}</th>
        <th scope="col" v-html="$t('tablasNutricionales.cada100Gpolvo_br')"></th>
        <th scope="col">{{ $t('tablasNutricionales.cada100ml' , {pv:"23"} ) }}</th>
        <th scope="col">{{ $t('tablasNutricionales.idr') }}</th>
      </tr>
    </thead>
    <tbody class="text-center">
      <tr>
        <td>{{ $t('tablasNutricionales.valor_energetico') }}</td>
        <td>Kcal</td>
        <td>437</td>
        <td>100</td>
        <td>5%</td>
      </tr>
      <tr>
        <td>&nbsp;</td>
        <td>Kj</td>
        <td>1829</td>
        <td>418</td>
        <td></td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.carbohidratos') }}</td>
        <td>g</td>
        <td>59</td>
        <td>14</td>
        <td>5%</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.proteinas') }}</td>
        <td>g</td>
        <td>17</td>
        <td>3,9</td>
        <td>5%</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_totales') }}</td>
        <td>g</td>
        <td>15</td>
        <td>3,4</td>
        <td>6%</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_saturadas') }}</td>
        <td>g</td>
        <td>1,8</td>
        <td>0,41</td>
        <td>2%</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_monoinsaturadas') }}</td>
        <td>g</td>
        <td>11</td>
        <td>2,6</td>
        <td>-</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_poliinsaturadas') }}</td>
        <td>g</td>
        <td>1,5</td>
        <td>0,34</td>
        <td>-</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.acido_linoleico_omega_6') }}</td>
        <td>g</td>
        <td>1,2</td>
        <td>0,28</td>
        <td>-</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.dha_omega_3') }}</td>
        <td>mg</td>
        <td>170</td>
        <td>39</td>
        <td>-</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.epa_omega_3') }}</td>
        <td>mg</td>
        <td>32</td>
        <td>7,2</td>
        <td>-</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.colesterol') }}</td>
        <td>mg</td>
        <td>0</td>
        <td>0</td>
        <td>-</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.grasas_trans') }}</td>
        <td>g</td>
        <td>0</td>
        <td>0</td>
        <td>-</td>
      </tr>
      <tr class="resaltado">
        <td>{{ $t('tablasNutricionales.fibra_alimentaria') }}</td>
        <td>g</td>
        <td>0</td>
        <td>0</td>
        <td>0%</td>
      </tr>
      <tr class="resaltado">
        <th scope="row" colspan="5">{{ $t('tablasNutricionales.minerales') }}</th>
      </tr>

      <tr>
        <td>{{ $t('tablasNutricionales.sodio') }}</td>
        <td>mg</td>
        <td>316</td>
        <td>73</td>
        <td>3%</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.potasio') }}</td>
        <td>mg</td>
        <td>588</td>
        <td>135</td>
        <td>-</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.cloro') }}</td>
        <td>mg</td>
        <td>517</td>
        <td>119</td>
        <td>-</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.calcio') }}</td>
        <td>mg</td>
        <td>350</td>
        <td>80</td>
        <td>8%</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.fosforo') }}</td>
        <td>mg</td>
        <td>220</td>
        <td>51</td>
        <td>7%</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.magnesio') }}</td>
        <td>mg</td>
        <td>87</td>
        <td>20</td>
        <td>8%</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.hierro') }}</td>
        <td>mg</td>
        <td>7,0</td>
        <td>1,6</td>
        <td>12%</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.zinc') }}</td>
        <td>mg</td>
        <td>4,7</td>
        <td>1,1</td>
        <td>15%</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.cobre') }}</td>
        <td>mg</td>
        <td>0,81</td>
        <td>0,19</td>
        <td>21%</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.manganeso') }}</td>
        <td>mg</td>
        <td>1,1</td>
        <td>0,25</td>
        <td>11%</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.fluor') }}</td>
        <td>mg</td>
        <td>0,50</td>
        <td>0,12</td>
        <td>3%</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.molibdeno') }}</td>
        <td>mcg</td>
        <td>42</td>
        <td>10</td>
        <td>21%</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.selenio') }}</td>
        <td>mcg</td>
        <td>21</td>
        <td>4,8</td>
        <td>14%</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.cromo') }}</td>
        <td>mcg</td>
        <td>20</td>
        <td>4,6</td>
        <td>13%</td>
      </tr>
      <tr class="resaltado">
        <td>{{ $t('tablasNutricionales.iodo') }}</td>
        <td>mcg</td>
        <td>48</td>
        <td>11</td>
        <td>8%</td>
      </tr>
      <tr class="resaltado">
        <th scope="row" colspan="5">{{ $t('tablasNutricionales.vitaminas') }}</th>
      </tr>

      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_a') }}</td>
        <td>mcg RE</td>
        <td>360</td>
        <td>83</td>
        <td>14%</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_d') }}</td>
        <td>mcg</td>
        <td>3,3</td>
        <td>0,76</td>
        <td>15%</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_e') }}</td>
        <td>mg alfa TE</td>
        <td>11</td>
        <td>2,5</td>
        <td>25%</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_k') }}</td>
        <td>mcg</td>
        <td>24</td>
        <td>5,5</td>
        <td>8%</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.tiamina_vit_b_1') }}</td>
        <td>mg</td>
        <td>0,75</td>
        <td>0,17</td>
        <td>14%</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.riboflavina_vit_b_2') }}</td>
        <td>mg</td>
        <td>0,75</td>
        <td>0,17</td>
        <td>13%</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.niacina_vit_b_3') }}</td>
        <td>mg</td>
        <td>9,0</td>
        <td>2,1</td>
        <td>13%</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.acido_pantotenico') }}</td>
        <td>mg</td>
        <td>3,1</td>
        <td>0,71</td>
        <td>14%</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.piridoxina_vit_b_6') }}</td>
        <td>mg</td>
        <td>0,95</td>
        <td>0,22</td>
        <td>17%</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.acido_folico') }}</td>
        <td>mcg</td>
        <td>148</td>
        <td>34</td>
        <td>14%</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.cianocobalamina_vit_b_12') }}</td>
        <td>mcg</td>
        <td>2,4</td>
        <td>0,55</td>
        <td>23%</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.biotina') }}</td>
        <td>mcg</td>
        <td>20</td>
        <td>4,6</td>
        <td>15%</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.vitamina_c') }}</td>
        <td>mg</td>
        <td>65</td>
        <td>15</td>
        <td>33%</td>
      </tr>
      <tr>
        <td>{{ $t('tablasNutricionales.colina') }}</td>
        <td>mg</td>
        <td>170</td>
        <td>39</td>
        <td>7%</td>
      </tr>
      <tr>
        <td colspan="5">{{ $t('tablasNutricionales.osmolaridad', {osmolaridad: 260, solutos:336}) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "TableSustentaNeutro",
};
</script>
